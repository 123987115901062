import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class User {
  getUserSelectList(status) {
    return axios.get(
      `/api/employer/select-list`,
      {
        params: {
          status: status,
          auth_method: 1,
          roles: [11, 13],
        },
      },
      config
    );
  }
  getUserList(id, email, status, cmt_factory_id, page, page_num) {
    return axios.get(
      `/api/employer/list`,
      {
        params: {
          id: id,
          email: email,
          status: status,
          cmt_factory_id: cmt_factory_id,
          auth_method: 1,
          roles: [11, 13],
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  editUserDetail(id, reporting_name, cmt_factory_id) {
    return axios.put(
      `/api/employer`,
      {
        id: id,
        reporting_name: reporting_name,
        cmt_factory_id: cmt_factory_id,
      },
      config
    );
  }
  editUser(id, status) {
    return axios.put(
      `/api/employer`,
      {
        id: id,
        status: status,
      },
      config
    );
  }
  checkUser() {
    return axios.get(
      `/api/auth/user`,
      {
        params: {},
      },
      config
    );
  }
  loginUser(email, sault, auth_method) {
    return axios.post(
      `/api/auth/login`,
      {
        email: email,
        sault: sault,
        auth_method: auth_method,
      },
      config
    );
  }
  logoutUser() {
    return axios.post(`/api/auth/logout`, {}, config);
  }
}
