<template>
  <div class="p-grid login-main p-jc-center p-ai-center">
    <Toast />
    <div style="width: 100vw; height: 100vh" class="card">
      <div class="p-grid">
        <transition-group
          name="p-message"
          tag="div"
          class="p-col-12"
          style="position: fixed; top: 10px; left: 0"
        >
          <Message
            v-for="msg of data.messages"
            :severity="msg.severity"
            :key="msg.id"
            >{{ msg.content }}</Message
          >
        </transition-group>
        <div class="p-col-12 p-md-8 d-phone">
          <img
            class="login-img"
            style="height: 100vh; width: 100%"
            src="../assets/af_login_back.png"
          />
        </div>
        <!-- <div class="d-phone-show p-col-12 p-mb-5">
          <br />
        </div> -->
        <!-- <div class="p-md-1 p-jc-center d-phone">
          <Divider class="divide-white" type="dashed" layout="vertical">
          </Divider>
        </div> -->
        <div class="p-col-12 p-md-4 p-pl-4 p-pr-4">
          <div
            class="p-fluid"
            style="padding: 15px; height: calc(100vh - 28px)"
          >
            <div style="width: 100%" class="p-d-flex p-jc-center p-mb-2">
              <img style="width: 90%" src="../assets/af_logo01.jpg" />
            </div>
            <div class="p-field">
              <span class="p-input-icon-left p-mr-1">
                <Dropdown
                  style="height: 50px; line-height: 35px"
                  v-model="data.webSearch"
                  :options="data.selectWebList"
                  optionLabel="name"
                  :placeholder="i18n.$t('Login').Web"
                >
                  <template #value="slotProps">
                    <div
                      class="country-item country-item-value"
                      v-if="slotProps.value"
                    >
                      <div>{{ slotProps.value.name }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="country-item">
                      <div>{{ slotProps.option.name }}</div>
                    </div>
                  </template>
                </Dropdown>
              </span>
            </div>
            <div class="p-field">
              <span class="p-input-icon-left p-mr-1">
                <Dropdown
                  class="w-full md:w-14rem"
                  style="height: 50px; line-height: 35px"
                  v-model="data.languagSearch"
                  :options="data.selectLanguageList"
                  optionLabel="name"
                  :placeholder="i18n.$t('Login').Language"
                >
                  <template #value="slotProps">
                    <div
                      class="country-item country-item-value"
                      v-if="slotProps.value"
                    >
                      <div>{{ slotProps.value.name }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="country-item">
                      <div>{{ slotProps.option.name }}</div>
                    </div>
                  </template>
                </Dropdown>
              </span>
            </div>
            <div class="p-field">
              <!-- <label class="text-white" for="email">{{
                i18n.$t("Login").Email
              }}</label>
              <InputText
                v-model="data.email"
                id="email"
                type="text"
                v-on:keyup.enter="Login()"
              /> -->
              <span style="color: black" class="p-input-icon-left">
                <i class="pi pi-user" />
                <InputText
                  size="large"
                  v-model="data.email"
                  id="email"
                  type="text"
                  :placeholder="i18n.$t('Login').Account"
                  v-on:keyup.enter="Login()"
                  style="height: 50px"
                />
              </span>
            </div>
            <div class="p-field">
              <!-- <label class="text-white" for="password">
                {{ i18n.$t("Login").Password }}
              </label> -->
              <!-- <InputText
                v-model="data.password"
                id="password"
                type="password"
                v-on:keyup.enter="Login()"
              /> -->
              <span style="color: black" class="p-input-icon-left">
                <i class="pi pi-lock" />
                <InputText
                  size="large"
                  v-model="data.password"
                  id="password"
                  type="password"
                  :placeholder="i18n.$t('Login').Password"
                  v-on:keyup.enter="Login()"
                  style="height: 50px"
                />
              </span>
            </div>
            <div class="p-field p-d-none">
              <Dropdown
                v-model="data.login_choose"
                :options="data.login_type"
                optionLabel="name"
                optionValue="code"
                placeholder="Select Company or Personal"
              />
            </div>
            <Dialog
              v-model:visible="data.display_register"
              class="show_dialog"
              :modal="true"
            >
              <template #header>
                <h3>{{ i18n.$t("Login").Register }}</h3>
              </template>
              <transition-group name="p-message" tag="div">
                <Message
                  v-for="msg of data.register_messages"
                  :severity="msg.severity"
                  :key="msg.id"
                  >{{ msg.content }}</Message
                >
              </transition-group>
              <div class="p-fluid">
                <div class="p-field">
                  <label for="register_email">{{
                    i18n.$t("Login").Email
                  }}</label>
                  <InputText
                    v-model="data.register_email"
                    id="register_email"
                    type="text"
                  />
                </div>
                <div class="p-field">
                  <label for="register_password">{{
                    i18n.$t("Login").Password
                  }}</label>
                  <InputText
                    v-model="data.register_password"
                    id="register_password"
                    type="password"
                  />
                </div>
                <div class="p-field">
                  <label for="register_check_password">{{
                    i18n.$t("Login").ConfirmPassword
                  }}</label>
                  <InputText
                    v-model="data.register_check_password"
                    id="register_check_password"
                    type="password"
                  />
                </div>
                <div class="p-field p-d-none">
                  <label for="register_name">Name</label>
                  <InputText
                    v-model="data.register_name"
                    id="register_name"
                    type="text"
                  />
                </div>
                <div class="p-field p-d-none">
                  <Dropdown
                    v-model="data.register_choose"
                    :options="data.login_type"
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Select Company or Personal"
                  />
                </div>
              </div>

              <template #footer>
                <Button
                  @click="data.display_register = false"
                  :label="i18n.$t('Login').Cancel"
                  icon="pi pi-times"
                  class="p-button-text"
                />
                <Button
                  @click="Register"
                  :label="i18n.$t('Login').Register"
                  icon="pi pi-check"
                  autofocus
                />
              </template>
            </Dialog>
            <Dialog
              v-model:visible="data.display_forget_password"
              class="show_dialog"
              :modal="true"
            >
              <template #header>
                <h3>Forget Password</h3>
              </template>
              <div class="p-fluid">
                <div class="p-field">
                  <label for="forget_email">Email</label>
                  <InputText
                    v-model="data.forget_email"
                    id="forget_email"
                    type="text"
                  />
                </div>
              </div>
              <template #footer>
                <Button
                  @click="data.display_forget_password = false"
                  label="No"
                  icon="pi pi-times"
                  class="p-button-text"
                />
                <Button
                  @click="ForgetPassword"
                  label="Yes"
                  icon="pi pi-check"
                  autofocus
                />
              </template>
            </Dialog>
            <div class="p-grid p-mb-2 p-d-none">
              <div class="p-col-12 p-text-right">
                <span @click="RegisterShow" class="font-join text-white">{{
                  i18n.$t("Login").Register
                }}</span>
                <br />
                <span
                  @click="data.display_forget_password = true"
                  class="font-join text-white p-d-none"
                  >Forget Password</span
                >
              </div>
            </div>

            <Button
              class="p-mb-2 p-mt-4 p-button-info p-button-lg"
              icon="pi pi-user"
              iconPos="left"
              :label="i18n.$t('Login').Login"
              @click="Login"
            ></Button>

            <Button
              class="p-mb-2 p-mt-4 p-button-danger p-button-lg p-d-none"
              icon="pi pi-user"
              iconPos="left"
              :label="i18n.$t('Login').FireBaseLogin"
              @click="GoogleLogin"
            ></Button>

            <Button
              class="p-mb-2 p-button-warning p-d-none"
              icon="pi pi-user"
              iconPos="left"
              :label="i18n.$t('Login').GuesterLogin"
              @click="GuestLogin"
            ></Button>
            <Button
              class="p-mb-2 p-button-info p-d-none"
              icon="pi pi-facebook"
              iconPos="left"
              :label="i18n.$t('Login').FacebookLogin"
              @click="FacebookLogin"
            ></Button>
            <Button
              icon="pi pi-google"
              iconPos="left"
              class="p-button-danger p-d-none"
              :label="i18n.$t('Login').GoogleLogin"
              @click="GoogleLogin"
            ></Button>
          </div>
          <div style="font-size: 14px" class="p-field p-text-center p-mb-1">
            <a
              style="text-decoration: none; color: black"
              href="https://leyutechs.com/"
              target="_blank"
            >
              <span style="color: rgb(71, 173, 236); font-weight: 900">@</span>
              2023 Leyu Technology Co. Ltd. All Rights Reserved
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, toRefs, computed, watch } from "vue";
// eslint-disable-next-line no-unused-vars
import Button from "primevue/button";
import Divider from "primevue/divider";
import InputText from "primevue/inputText";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import Message from "primevue/message";
import { useStore } from "vuex";
// import firebase from "firebase";
import { useI18n } from "../i18nPlugin";
// import User from "../api/common/user";
// let userAPI = new User();
import router from "../router";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import User from "../api/user";
import firebase from "firebase";
import cookie from "../cookie";

let cookieSetting = new cookie();
let userApi = new User();

const toast = useToast();
const i18n = useI18n();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
      if (res.data.data.role == 11 || res.data.data.role == 13) {
        if (res.data.data.id == 216) {
          router.push("/schedule");
        } else {
          router.push("/board-room");
        }
      } else {
        router.push("/");
      }
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

function Login() {
  userApi.loginUser(data.email, data.password, 1).then((res) => {
    if (res.data.error_code == 0) {
      CheckUser();
      // router.push("/");
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });

  //   if (
  //     data.email == import.meta.env.VITE_Admin_Account &&
  //     data.password == import.meta.env.VITE_Admin_Pass
  //   ) {
  //     store.commit("set_login", "admin");
  //     router.push("/material");
  //   } else if (
  //     data.email == import.meta.env.VITE_Account &&
  //     data.password == import.meta.env.VITE_Pass
  //   ) {
  //     store.commit("set_login", "normal");
  //     router.push("/material");
  //   } else {
  //     toast.add({
  //       severity: "warn",
  //       summary: "Warn Message",
  //       detail: "Wrong Password or Account",
  //       life: 3000,
  //     });
  //   }
  //   firebase
  //     .auth()
  //     .signInWithEmailAndPassword(data.email, data.password) // THIS LINE CHANGED
  //     .then((data) => {
  //       data.messages.push({
  //         severity: "success",
  //         content: "Login Success!!",
  //         id: 0,
  //       });
  //     })
  //     .catch((error) => {
  //       data.messages.push({
  //         severity: "warn",
  //         content: error.message,
  //         id: 0,
  //       });
  //     });
}

function GoogleLogin() {
  // store.commit("set_login", true);
  var provider = new firebase.auth.GoogleAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;

      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      userApi.loginUser(user.email, user.uid, 2).then((res) => {
        if (res.data.error_code == 0) {
          CheckUser();
          router.push("order-agent");
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      // ...
      console.log("Google Auth OK = ", result);
    })
    .catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
      console.log("Google Auth Error = ", error);
    });
}

function FireBaseLogin() {
  firebase
    .auth()
    .signInWithEmailAndPassword(data.email, data.password) // THIS LINE CHANGED
    .then((data) => {
      userApi.loginUser(data.user.email, data.user.uid, 2).then((res) => {
        if (res.data.error_code == 0) {
          CheckUser();
          router.push("order-agent");
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
    })
    .catch((error) => {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: error.messaget,
        life: 3000,
      });
    });
}

function RegisterShow() {
  data.display_register = true;
  data.messages = [];
  data.register_messages = [];
  data.register_email = "";
  data.register_password = "";
  data.register_check_password = "";
  data.register_name = "";
}
async function Register() {
  data.register_messages = [];
  data.messages = [];
  if (data.register_password === data.register_check_password) {
    // firebase
    //   .auth() // get the auth api
    //   .createUserWithEmailAndPassword(
    //     data.register_email,
    //     data.register_password
    //   ) // need .value because ref()
    //   .then((data) => {
    //     // console.log("Successfully registered!");
    //     data.messages.push({
    //       severity: "success",
    //       content: "Register Success!!",
    //       id: 0,
    //     });
    //     data.display_register = false;
    //   })
    //   .catch((error) => {
    //     data.register_messages.push({
    //       severity: "warn",
    //       content: error.message,
    //       id: 0,
    //     });
    //   });
    // memberAPICall
    //   .registerMember(
    //     data.register_email,
    //     data.register_password,
    //     data.register_name
    //   )
    //   .then((res) => {
    //     if (res.data.error_code == 0) {
    //       data.messages.push({
    //         severity: "success",
    //         content: "Register Success!!",
    //         id: 0,
    //       });
    //       data.display_register = false;
    //     } else {
    //       data.register_messages.push({
    //         severity: "warn",
    //         content: res.data.error_text,
    //         id: 0,
    //       });
    //     }
    //   });
  } else {
    data.count = data.count + 1;
    data.register_messages = [
      {
        severity: "warn",
        content: "Please check Password!!",
        id: data.count,
      },
    ];
  }
}

function ForgetPassword() {
  console.log("ForgetPassword");
}

const mounted = onMounted(async () => {
  // await fetchUserInfo();
  let lang = cookieSetting.getCookie("lang");
  if (lang != "") {
    store.commit("set_lang", lang);
    if (lang == "en") {
      data.languagSearch = { name: "English", code: "en" };
    } else {
      data.languagSearch = { name: "中文", code: "cn" };
    }
  } else {
    store.commit("set_lang", "cn");
    data.languagSearch = { name: "中文", code: "cn" };
  }
});
const data = reactive({
  webSearch: {
    id: 1,
    name: "訂單＆倉儲(Order&Warehouse)",
    url: "https://africa.leyutechs.com/",
  },
  languagSearch: { name: "中文", code: "cn" },
  selectWebList: [
    {
      id: 1,
      name: "訂單＆倉儲(Order&Warehouse)",
      url: "https://africa.leyutechs.com/",
    },
    {
      id: 2,
      name: "外籍薪資(Payment Application System)",
      url: "https://africa-hrm.leyutechs.com/",
    },
    {
      id: 3,
      name: "當地薪資(Local payroll System)",
      url: "https://africa-lhrm.leyutechs.com/",
    },
  ],
  selectLanguageList: [
    { name: "中文", code: "cn" },
    { name: "English", code: "en" },
  ],
  count: 0,
  messages: [],
  register_messages: [],
  register_choose: 2,
  login_choose: 2,
  register_email: "",
  register_password: "",
  register_check_password: "",
  register_name: "",
  email: "",
  password: "",
  forget_email: "",
  display_register: false,
  display_forget_password: false,
  login_type: [
    { name: "Company", code: 1 },
    { name: "Personal", code: 2 },
  ],
});

watch(
  () => data.languagSearch,
  async () => {
    await store.commit("set_lang", data.languagSearch.code);
    cookieSetting.doCookieSetup("lang", store.state.lang);
    i18n.locale.value = store.state.lang;
  }
);

watch(
  () => data.webSearch,
  async () => {
    document.location.href = data.webSearch.url;
  }
);
</script>

<style>
.show_dialog {
  width: 50vw;
}
.text-white {
  color: white !important;
}
.d-phone {
  display: flex;
}
.d-phone-show {
  display: none;
}
@media screen and (max-width: 600px) {
  .show_dialog {
    width: 90vw;
  }
  .d-phone {
    display: none;
  }
  .d-phone-show {
    display: flex;
  }
}
.font-join {
  font-size: 0.5rem;
  cursor: pointer;
}
.divide-white {
  width: 1px;
  background-color: white;
}
.login-img {
  max-width: 95%;
  height: auto;
}
.login-main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  /* background-color: var(--surface-50); */
  /* background-image: url("../assets/login_background.jpg");
  background-repeat: no-repeat;
  background-size: cover; */
  color: var(--text-color);
}
</style>
